const INFO = {
	main: {
		title: "Kashif Sattar",
		name: "Kashif C.",
		email: "kashi.dev96@gmail.com",
		logo: "../logo.jpg",
	},

	socials: {
		twitter: "https://twitter.com/k_ashi0",
		github: "https://github.com/KashifCh-eth",
		linkedin: "https://www.linkedin.com/in/kashif-choudary/",
 		telegram: "https://t.me/kashi_eth",
		// facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Full Stack Blockchain Developer.",
		description: "I'm a passionate blockchain developer with a proven ability to design, build, and launch secure, scalable decentralized applications (dApps).  I leverage my experience in cutting-edge frameworks and technologies to tackle complex challenges and deliver high-quality, industry-standard code.  I'm a constant learner, always seeking new opportunities to push the boundaries and grow as a developer."
 	},

	about: {
		title: "I’m Kashif C. ",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I break barriers, building a brighter future through inspired minds.",
		description:
			"Building, guiding, shaping: My long-form chronicles of a digital adventurer.",
		
	},

	projects: [
		{
			title: "Blockchain Voting System",
			description:
				"Decentralized Election voting System project using Blockchain technology such as Metamask, hardhat, and Remix IDE..",
			logo: "../ethereum.svg",
			linkText: "View Project",
			link: "https://github.com/KashifCh-eth/blockchain-voting-system-.git",
		},

		{
			title: " Blockchain Charity Website",
			description:
				"donation solution, which provides an ecosystem for nonprofits and charities to fundraise.",
			logo: "../block.svg",
			linkText: "View Project",
			link: "https://github.com/KashifCh-eth/Charity-Website-Based-on-Blockchain-.git",
		},

		{
			title: "Ecommerce-website",
			description:
				"Ecommerce website design involves creating and launching a virtual store to sell products. We're not talking about listing your items on a third-party online .",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/KashifCh-eth/ecommerce-website-with-postgres-authentication-based-on-reactjs-and-nodejs",
		},

		{
			title: "Blockchain Based Todo-list",
			description:
				"first blockchain application! created a todo list powered by Ethereum smart contracts.",
			logo: "../ethereum.svg",
			linkText: "View Project",
			link: "https://github.com/KashifCh-eth/todo-list-dapp-using-solidity-etherjs-reactjs",
		},

		{
			title: "Minting Landing Pages",
			description:
				"cloneable of a NFT Landing and Minting page. This project was never launched so I thought it would be great to share it with Webflow community .",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/KashifCh-eth/nft-collection-landing-page-",
		},
	],
};

export default INFO;
