const SEO = [
	{
		page: "home",
		description:
			" I am a passionate and experienced blockchain developer with a proven track record of success in designing, developing, and deploying decentralized applications. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
		keywords: ["kashifCh_eth", "Kashif C.", "KashifCh-eth", "k_ashi0", "kashif-choudary", "kashi_st0", "kashif sattar"],
	},
	{
		page: "about",
		description:
			" I am a passionate and experienced blockchain developer with a proven track record of success in designing, developing, and deploying decentralized applications. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
		keywords: ["kashifCh_eth", "Kashif C.", "KashifCh-eth", "k_ashi0", "kashif-choudary", "kashi_st0", "kashif sattar"],
	},
	{
		page: "articles",
		description:
			" I am a passionate and experienced blockchain developer with a proven track record of success in designing, developing, and deploying decentralized applications. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
		keywords: ["kashifCh_eth", "Kashif C.", "KashifCh-eth", "k_ashi0", "kashif-choudary", "kashi_st0", "kashif sattar"],
	},

	{
		page: "projects",
		description:
			" I am a passionate and experienced blockchain developer with a proven track record of success in designing, developing, and deploying decentralized applications. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
		keywords: ["kashifCh_eth", "Kashif C.", "KashifCh-eth", "k_ashi0", "kashif-choudary", "kashi_st0", "kashif sattar"],
	},

	{
		page: "contact",
		description:
			" I am a passionate and experienced blockchain developer with a proven track record of success in designing, developing, and deploying decentralized applications. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
		keywords: ["kashifCh_eth", "Kashif C.", "KashifCh-eth", "k_ashi0", "kashif-choudary", "kashi_st0", "kashif sattar"],
	},
];

export default SEO;
